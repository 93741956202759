import { getEmailCode, getSavePassword } from "@/api/auth/login"
import { registerEmailCode, register } from '@/api/auth/register'
import { captcha } from "@/api/website"
import { checkEmail } from "@/api/member/security"

export default {
    name: "forgottenPassword",
    components: {

    },
    data: () => {
        return {
            codeShow: true,
            email: "",
            code: "",
            captcha: {
                id: 0,
                img: ''
            },
            emailCode: "",
            codeError: false,
            loading: false
        }
    },
    watch: {
    },
    created() {        
    },
    mounted() {
        this.getCaptcha();
    },
    computed: {
    },
    methods: {
        backClick() {
            this.$router.go(-1);
        },
        codeClick() {
            this.loading = true;
            checkEmail({ email: this.email }).then( res =>{
                if(res.code == 0) {
                    this.$message.error("The current mailbox does not exist");
                    this.loading = false;
                }
            }).catch( err => {
                if(err.code == -1){
                    const data = {
                        email: this.email,
                        captcha_id: this.captcha.id,
                        captcha_code: this.code
                    }
                    getEmailCode(data).then( res => {
                        if(res.code == 0){
                            this.$message.success("Verification code sent");
                            localStorage.setItem('emailSoyego', this.email);
                            localStorage.setItem('emailKeySoyego', res.data.key);
                            this.loading = false;
                            this.$router.push({ path: "/emailCode" })
                        }
                    }).catch( err => {
                        this.$message.error("Please enter the correct email address");
                        this.loading = false;
                    })
                }
            })           
        },
        /**
         * 获取验证码
         */
        getCaptcha() {
            this.captcha.img = "";
            captcha({
                captcha_id: this.captcha.id
            }).then(res => {
                if (res.code >= 0) {
                    this.captcha.id = res.data.id
                    this.captcha.img = res.data.img
                    this.captcha.img = this.captcha.img.replace(/\r\n/g, "")
                }
            }).catch(err => {
                this.$message.error(err.message)
            })
        },
    }
}
