<template>
    <div class="forgottenPassword" v-loading="loading">
        <div class="warp">
            <div class="main">
                <h5>Forgotten Password</h5>
                <p>Please fill in the account for which you need to retrieve the password, We'll send you a link to reset your password.</p>
                <div class="text">
                    <span>Email</span>
                    <input type="text" placeholder="Enter your email" v-model="email" />
                </div>
                <div class="code">
                    <span>Dynamic Code</span>
                    <div class="textCode">
                        <input type="text" placeholder="Enter the number on the right" v-model="code" />
                        <div class="img" @click="getCaptcha()">
                            <el-image :lazy-src="captcha.img" :src="captcha.img" style="cursor: pointer; width: 100%; height: 100%" alt="Loading failed">
                                <div slot="error">Loading failed</div>
                                <div slot="placeholder">Loading failed</div>
                            </el-image>
                        </div>
                    </div>
                </div>
                <div class="btn">
                    <button class="btn-border" @click="backClick">CANCEL</button>
                    <button class="btn-background" @click="codeClick">CONTINUE</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import forgottenPassword from "./js/forgottenPassword.js"
import verifyCode from "./verifyCode.vue"

export default {
    name: "forgottenPassword",
    components: {
        verifyCode
    },
    mixins: [forgottenPassword]
}
</script>

<style lang="scss" scoped>
.forgottenPassword {
    width: 100%;
    margin-top: 40px;

    .warp {
        max-width: 1200px;
        width: 100%;
        // height: 794px;
        margin: 0 auto;
        background: #fff;
        padding-bottom: 100px;

        .main {
            width: 422px;
            display: flex;
            flex-direction: column;
            margin: 0 auto;

            h5 {
                text-align: center;
                font-size: 24px;
                font-family: Montserrat;
                font-weight: bold;
                color: #330000;
                line-height: 28px;
                padding-bottom: 14px;
                padding-top: 40px;
            }

            p {
                font-size: 12px;
                font-family: Montserrat;
                font-weight: 400;
                color: #68696a;
                line-height: 14px;
                padding-bottom: 40px;
            }

            .text {
                display: flex;
                flex-direction: column;

                span {
                    font-size: 12px;
                    font-family: Montserrat;
                    font-weight: 400;
                    color: #68696a;
                    line-height: 14px;
                    padding-bottom: 15px;
                }

                input {
                    outline: none;
                    border: none;
                    font-size: 14px;
                    font-family: Montserrat;
                    font-weight: 400;
                    color: #a6aaad;
                    line-height: 46px;
                    border-bottom: 1px solid #d8dadc;
                }
            }

            .code {
                padding-top: 40px;
                display: flex;
                flex-direction: column;

                span {
                    font-size: 12px;
                    font-family: Montserrat;
                    font-weight: 400;
                    color: #68696a;
                    line-height: 14px;
                    padding-bottom: 15px;
                }

                .textCode {
                    display: flex;

                    input {
                        outline: none;
                        border: none;
                        font-size: 14px;
                        font-family: Montserrat;
                        font-weight: 400;
                        color: #a6aaad;
                        line-height: 46px;
                        border-bottom: 1px solid #d8dadc;
                        width: calc(100% - 93px);
                    }

                    .img {
                        width: 93px;
                        height: 46px;
                    }
                }
            }

            .btn {
                padding-top: 60px;
                width: 100%;
                display: flex;
                justify-content: space-between;

                button {
                    outline: none;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .btn-border {
                    width: 200px;
                    height: 54px;
                    border: 1px solid #330000;
                    font-size: 18px;
                    font-family: Montserrat;
                    font-weight: bold;
                    color: #330000;
                    background: transparent;
                }

                .btn-background {
                    width: 200px;
                    height: 54px;
                    background: #330000;
                    font-size: 18px;
                    font-family: Montserrat;
                    font-weight: bold;
                    color: #ffffff;
                }
            }
        }
    }
}
</style>